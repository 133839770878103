<template>
  <div class="container">
    <el-form :model="form"
             :rules="rules"
             label-position="top"
             ref="form">
      <el-form-item label="礼品名称"
                    class="width300"
                    prop="name">
        <el-input type="text"
                  placeholder="请输入礼品名称"
                  v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="礼品图片"
                    prop="img_id">
        <ImgUpload :fileList="imgList"
                   pixel="用于页面配图，建议使用200*200像素"
                   v-model="form.img_id" />
      </el-form-item>
      <el-form-item label="礼品介绍"
                    class="width300"
                    prop="intro">
        <el-input type="textarea"
                  :row="4"
                  placeholder="请输入礼品介绍"
                  v-model="form.intro"></el-input>
      </el-form-item>
      <el-form-item label="市场价格"
                    class="width300"
                    prop="market_price">
        <el-input type="number"
                  placeholder="请输入数字"
                  v-model="form.market_price"></el-input>
      </el-form-item>
      <el-form-item label="售价"
                    class="width300"
                    prop="price">
        <el-input type="number"
                  placeholder="请输入数字"
                  v-model="form.price"></el-input>
      </el-form-item>

      <el-form-item label="是否启用">
        <el-switch v-model="form.is_shelves"
                   :active-value="1"
                   :inactive-value="0">
        </el-switch>
      </el-form-item>
      <el-form-item label="上架库存"
                    style="width:400px;">
        <el-table :data="form.gift_quantity_arr"
                  border>
          <el-table-column prop="name"
                           label="网点名称">
          </el-table-column>
          <el-table-column label="库存">
            <template slot-scope="scope">
              <el-input type="text"
                        placeholder="请输入数字"
                        v-model="scope.row.quantity"></el-input>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   @click="submitForm('form')">立即{{form.id?'修改':'创建'}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { setGift, getGift, fetchActivityAreas } from '@/api/market'
import ImgUpload from '@/components/ImgUpload'
import { getImgId } from '@/utils'
export default {
  name: 'SetGift',
  data () {
    return {
      imgList: [],
      areas: [],
      form: {
        name: '',
        intro: '',
        market_price: 1,
        img_id: '',
        is_shelves: true,
        price: 1,
        quantity: 1,
        gift_quantity_arr: []
      },
      rules: {
        name: [
          { required: true, message: '请输入礼品名称', trigger: 'blur' }
        ],
        img_id: [
          { required: true, message: '请上传礼品图片', trigger: 'change' }
        ],
        market_price: [
          { required: true, message: '请输入市场价', trigger: 'blur' }
        ],
        price: [
          { required: true, message: '请选择价格', trigger: 'blur' }
        ]
      }
    }
  },
  components: {
    ImgUpload
  },
  methods: {
    async fetchActivityAreas () {
      const res = await fetchActivityAreas({
        activity_id: this.$route.query.activity_id
      })
      if (res.meta.code === 0) {
        this.areas = res.data.areas
        const list = []

        if (!this.$route.query.id) {
          this.areas.forEach((v, k) => {
            list.push({
              area_id: v.id,
              name: v.name,
              quantity: 0
            })
          })
          this.form.gift_quantity_arr = list
        }
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.form.activity_id = this.$route.query.activity_id
          const res = await setGift(this.form)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: `${this.form.id ? '修改' : '创建'}成功`,
              type: 'success'
            })
            this.$router.push({
              name: 'Gifts',
              query: { id: this.$route.query.activity_id }
            })
          } else {
            this.$message.error(res.meta.msg)
          }
        }
      })
    },
    async getGift (id) {
      const res = await getGift({
        activity_id: this.$route.query.activity_id,
        gift_id: this.$route.query.id
      })
      if (res.meta.code === 0) {
        const gift = res.data.gift
        gift.gift_id = gift.id
        gift.img_id = getImgId(gift.img_data)
        gift.img_data && this.$set(this.imgList, 0, gift.img_data)
        this.form = gift
      }
    }
  },
  mounted () {
    if (this.$route.query.activity_id) {
      this.fetchActivityAreas()
    }
    if (this.$route.query.id) {
      this.getGift()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
